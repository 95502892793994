import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Element, useEditor, useNode } from '@craftjs/core';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';

import {
  TableContainer as MuiTableContainer,
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  IconButton,
  Icon,
  Collapse,
  Box,
  FormControl,
  Grid,
  Select,
  MenuItem,
  InputLabel
} from '@material-ui/core';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { makeStyles } from '@material-ui/core/styles';

import {
  Container,
  ContainerSettings,
  ContainerDefaultProps,
  containerMoveInRule
} from './Container';
import { useStylesSelected } from './SelectedWrap';
import { TabCanvasForceUpdateContext } from './Tabs';

export const OrientationValues = {
  horizontal: 'horizontal',
  vertical: 'vertical'
};

export const TableColumnDataCategory = {
  generalFieldType: null,
  customFieldType: null,
  bulkDataType: null
};

export const TableDataContext = createContext([false, null]);

export const CellCanvas = ({ background, padding, dataSource, children }) => {
  const [, changeTableColumnDataCategory] = useContext(TableDataContext);
  const forceUpdateTabCanvas = useContext(TabCanvasForceUpdateContext);
  const [isNewCell, setIsNewCell] = useState(true);

  const selectedClasses = useStylesSelected();
  const {
    connectors: { connect },
    id: nodeId,
    actions: { setProp }
  } = useNode();
  const {
    isSelected,
    query: { node: nodeMap }
  } = useEditor((state) => ({
    isSelected: state.events.selected === nodeId
  }));
  const thisNode = nodeMap(nodeId)?.get();
  const parentId = thisNode?.data.parent;
  const childIds = thisNode?.data.nodes;

  useEffect(() => {
    if (!isNewCell) {
      const tableColumnDataId = thisNode?.data.props.dataId;
      changeTableColumnDataCategory(tableColumnDataId, null);
    }

    if (forceUpdateTabCanvas) {
      forceUpdateTabCanvas();
    }
  }, [childIds]);

  useEffect(() => {
    if (parentId && (!dataSource || !dataSource.childOverride)) {
      const parentDataSource = nodeMap(parentId)?.get().data.props.dataSource;

      if (parentDataSource) {
        const newDataSource = {
          source: parentDataSource.source,
          childOverride: false
        };
        setProp((props) => (props.dataSource = newDataSource), 500);
      }
    }

    setIsNewCell(false);
  }, [parentId]);

  return (
    <div
      ref={connect}
      style={{
        background,
        padding: `${padding}px`,
        minHeight: '5rem'
      }}
      className={isSelected ? selectedClasses.selected : null}
    >
      {children}
    </div>
  );
};
CellCanvas.craft = {
  props: ContainerDefaultProps,
  related: {
    settings: ContainerSettings
  },
  rules: {
    canMoveIn: (incomingNode, currentNode, nodeMap) => {
      const incomingIsEditor = incomingNode.data.name.includes('Editor');
      if (!incomingIsEditor) {
        return false;
      }

      const childNodeIds = currentNode.data.nodes;
      if (childNodeIds && childNodeIds.length > 0) {
        return false;
      }

      return containerMoveInRule(incomingNode, currentNode, nodeMap);
    }
  }
};

export const useStylesTable = makeStyles(() => ({
  tableWrap: {
    marginTop: '1rem',
    '& th': {
      border: '1px solid rgba(224, 224, 224, 1)'
    },
    '& td': {
      border: '1px solid rgba(224, 224, 224, 1)',
      '& .instanceEditorComponent .MuiAutocomplete-root': {
       /*  width: '18rem !important' */
      }
    },
    '& .MuiTableCell-body > .MuiBox-root > .instanceEditorComponent': {
      margin: '0px 0px !important'
    },
    '& .MuiTableCell-body > .instanceEditorComponent': {
      margin: '0px 0px !important'
    },
    paddingBottom: '10px'
  },
  head: {
    backgroundColor: '#eaeaea',
    fontWeight: 'bold'
  },
  headerCell: {
    minWidth: '19rem'
  },
  controlCollapse: {
    width: '6.5rem'
  }
}));

export const Table = ({ columns }) => {
  const classes = useStylesTable();
  const forceUpdateTabCanvas = useContext(TabCanvasForceUpdateContext);
  const {
    actions: { setProp },
    id: nodeId,
    parentId,
    isMultipleData
  } = useNode((node) => ({
    parentId: node.data.parent,
    isMultipleData: node.data.props.isMultipleData
  }));
  const {
    query: { node: nodeMap },
    actions: { delete: deleteNode }
  } = useEditor();
  const parentDataSource = nodeMap(parentId)?.get().data.props.dataSource;

  const [openCollapseRow, setOpenCollapseRow] = useState(false);

  useEffect(() => {
    if (parentDataSource && !parentDataSource.singleData) {
      setProp((props) => {
        props.isMultipleData = true;
      }, 500);
    }

    if (columns) {
      return;
    }

    let initColumns = [];
    initColumns.push({
      dataId: 'tableData_' + new Date().getTime(),
      header: i18n.t('tabeditor.tools.table.firstColumn'),
      singleData: true
    });

    setProp((props) => ((props.columns = initColumns), 500));
  }, []);

  function changeTableColumnDataCategory(
    tableColumnDataId,
    tableColumnDataCategory
  ) {
    setProp((props) => {
      props.columns = props.columns.map((column) => {
        if (column.dataId === tableColumnDataId) {
          if (tableColumnDataCategory === null) {
            column.header = i18n.t('tabeditor.tools.table.emptyColumn');
            column.category = null;
          } else {
            column.header = tableColumnDataCategory.label;
            column.category = tableColumnDataCategory;
          }
        }
        return column;
      });
    }, 500);
  }

  function deleteColumn(targetIndex) {
    if (columns.length === 1) return;

    const dataIdTargetColumn = columns.find(
      (_, curIndex) => targetIndex === curIndex
    )?.dataId;
    const tableNodeData = nodeMap(nodeId)?.get().data;
    const tableCellNodeId = tableNodeData?.linkedNodes[dataIdTargetColumn];
    const nodeIdTarget = nodeMap(tableCellNodeId)?.get().data.nodes[0];

    const newColumns = columns.filter((_, curIndex) => targetIndex != curIndex);
    setProp((props) => (props.columns = newColumns));

    if (nodeIdTarget) {
      deleteNode(nodeIdTarget);
    }

    if (forceUpdateTabCanvas) {
      forceUpdateTabCanvas();
    }
  }

  function moveColumn(targetIndex, direction) {
    if (columns.length === 1) return;
    const newIndex = targetIndex + direction;
    if (newIndex < 0 || newIndex >= columns.length) return;

    const newColumns = JSON.parse(JSON.stringify(columns));
    var temp = newColumns[targetIndex];
    newColumns[targetIndex] = newColumns[newIndex];
    newColumns[newIndex] = temp;

    setProp((props) => (props.columns = newColumns));
  }

  if (!columns) {
    return null;
  } else {
    return (
      <Container>
        <MuiTableContainer
          className={classes.tableWrap}
          id={'table-in-node-id_' + nodeId}
        >
          <MuiTable aria-label="tab-editor-table">
            <MuiTableHead className={classes.head}>
              <MuiTableRow>
                {isMultipleData && <MuiTableCell />}
                {columns.map((column, index) => (
                  <MuiTableCell
                    key={column.dataId}
                    className={classes.headerCell}
                  >
                    <span className={classes.headerLabel}>{column.header?.text || column.header}</span>
                    {columns.length > 1 && (
                      <ColumnActions
                        tableNodeId={nodeId}
                        index={index}
                        deleteColumn={deleteColumn}
                        moveColumn={moveColumn}
                        columnsSize={columns.length}
                      />
                    )}
                  </MuiTableCell>
                ))}
              </MuiTableRow>
            </MuiTableHead>
            <MuiTableBody>
              <MuiTableRow hover={false}>
                {isMultipleData && (
                  <MuiTableCell className={classes.controlCollapse}>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => setOpenCollapseRow(!openCollapseRow)}
                    >
                      {openCollapseRow ? (
                        <Icon className="fa fa-caret-up" />
                      ) : (
                        <Icon className="fa fa-caret-down" />
                      )}
                    </IconButton>
                  </MuiTableCell>
                )}
                <TableDataContext.Provider
                  value={[true, changeTableColumnDataCategory]}
                >
                  {columns.map((column) => (
                    <MuiTableCell key={column.dataId}>
                      <Element
                        canvas
                        is={CellCanvas}
                        id={column.dataId}
                        dataId={column.dataId}
                        dataSource={{
                          source: parentDataSource.source,
                          singleData: true,
                          childOverride: parentDataSource.childOverride
                        }}
                      />
                    </MuiTableCell>
                  ))}
                </TableDataContext.Provider>
              </MuiTableRow>
              {isMultipleData && (
                <MuiTableRow>
                  <MuiTableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={columns.length + 1}
                  >
                    <Collapse in={openCollapseRow} timeout="auto" unmountOnExit>
                      <Box margin={1}>
                        <Element
                          canvas
                          is={Container}
                          id="collapseTableRow"
                          dataSource={{
                            source: parentDataSource.source,
                            singleData: true,
                            childOverride: parentDataSource.childOverride
                          }}
                        ></Element>
                      </Box>
                    </Collapse>
                  </MuiTableCell>
                </MuiTableRow>
              )}
            </MuiTableBody>
          </MuiTable>
        </MuiTableContainer>
      </Container>
    );
  }
};
Table.propTypes = {
  columns: PropTypes.array,
  orientation: PropTypes.any,
  isMultipleData: PropTypes.bool
};
export const TableDefaultProps = {
  tabs: null,
  orientation: OrientationValues.horizontal,
  isMultipleData: false
};

const useColumnActionsStyles = makeStyles(() => ({
  root: {
    transform: 'translateZ(0px)',
    flexGrow: 1
  },
  speedDial: {
    position: 'absolute',
    right: '0',
    bottom: '-16px'
  }
}));
export const ColumnActions = ({
  tableNodeId,
  index,
  deleteColumn,
  moveColumn,
  columnsSize
}) => {
  const classes = useColumnActionsStyles();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const toggleOpen = () => {
    setOpen(!open);
  };

  function deleteColumnAction() {
    deleteColumn(index);
    setOpen(false);
  }

  function moveColumnAction(direction) {
    moveColumn(index, direction);
    setOpen(false);
  }

  return (
    <div className={classes.root}>
      <SpeedDial
        ariaLabel={'edit table_' + tableNodeId + ' column_' + index}
        className={classes.speedDial}
        direction="left"
        FabProps={{ color: 'secondary', size: 'small' }}
        icon={<Icon className="fa fa-pencil" fontSize="small" />}
        onClick={toggleOpen}
        open={open}
      >
        {index < columnsSize - 1 && (
          <SpeedDialAction
            icon={<Icon className="fa fa-arrow-right" fontSize="small" />}
            tooltipTitle={t('common.forward')}
            onClick={() => {
              moveColumnAction(1);
            }}
          />
        )}
        <SpeedDialAction
          icon={
            <Icon
              className="fa fa-trash"
              style={{ color: '#d9534f' }}
              fontSize="small"
            />
          }
          tooltipTitle={t('common.delete')}
          onClick={deleteColumnAction}
        />
        {index > 0 && (
          <SpeedDialAction
            icon={<Icon className="fa fa-arrow-left" fontSize="small" />}
            tooltipTitle={t('common.back')}
            onClick={() => {
              moveColumnAction(-1);
            }}
          />
        )}
      </SpeedDial>
    </div>
  );
};
ColumnActions.craft = {
  rules: {
    canDrag: () => false,
    canMoveIn: () => false,
    canMoveOut: () => false
  }
};

export const useStylesTableSettings = makeStyles(() => ({
  buttonsWrap: {
    textAlign: 'center'
  },
  buttons: {
    marginTop: '1rem'
  }
}));
export const TableSettings = () => {
  const classes = useStylesTableSettings();
  const { t } = useTranslation();
  const {
    columns,
    orientation,
    actions: { setProp }
  } = useNode((node) => ({
    columns: node.data.props.columns,
    orientation: node.data.props.orientation
  }));

  function createColumn() {
    const newColumns = columns.concat({
      dataId: 'tableData_' + new Date().getTime(),
      header: i18n.t('tabeditor.tools.table.newColumn'),
      singleData: true
    });
    setProp((props) => (props.columns = newColumns));
  }

  function changeOrientation(event) {
    setProp((props) => {
      props.orientation = event.target.value;
    });
  }

  return (
    <>
      <Grid item className={classes.buttonsWrap}>
        <div>
          <button className="btn btn-success iconButton" onClick={createColumn}>
            <i className="fa fa-plus" aria-hidden="true"></i>
            <span>{t('tabeditor.tools.table.add')}</span>
          </button>
        </div>
      </Grid>
      <Grid item>
        <FormControl
          variant="outlined"
          component="fieldset"
          style={{ marginTop: '2rem' }}
        >
          <InputLabel id="tabs-config-orientation-label">
            {t('tabeditor.tools.tabs.orientation')}
          </InputLabel>
          <Select
            labelId="tabs-config-orientation-label"
            id="tabs-config-orientation"
            label={t('tabeditor.tools.tabs.orientation')}
            defaultValue={OrientationValues.horizontal}
            value={orientation}
            onChange={changeOrientation}
          >
            <MenuItem value={OrientationValues.horizontal}>
              {t('tabeditor.tools.tabs.horizontal')}
            </MenuItem>
            <MenuItem value={OrientationValues.vertical}>
              {t('tabeditor.tools.tabs.vertical')}
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </>
  );
};
Table.craft = {
  props: TableDefaultProps,
  related: {
    settings: TableSettings
  }
};

import React, { useState, useRef, useEffect } from 'react';

import JoditEditor from 'jodit-react';
import { Jodit } from 'jodit';

import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import { ToggleStandardValues } from './featurecomponents/ToggleStandardValues';
import { useDataManagement } from './DataManagementContext';
import { getFeatureKey } from './ArticleFeatureEditor';

import ajaxPost from '../../../services/ajaxPost';
import ajaxGet from 'src/services/ajaxGet';

import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';

const joditConfig = {
  enter: 'div',
  buttons: [
    'undo',
    'redo',
    '|',
    'ul',
    {
      icon: 'ol',
      command: 'insertOrderedList',
      tags: ['ol'],
      tooltip: 'Insert Ordered List',
      list: {
        default: 'Default',
        'lower-alpha': 'Lower Alpha',
        'upper-alpha': 'Upper Alpha'
      },
      exec:
        'function(e,t,n){var r=n.control,o="button"+r.command,a=r.args&&r.args[0]||i.dataBind(e,o);i.dataBind(e,o,a),e.execCommand(r.command,!1,a)}'
    },
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'superscript',
    'subscript',
    '|',
    'brush',
    'fullsize'
  ],
  beautyHTML: true,
  beautifyHTMLCDNUrlsJS: ['frameworks/beautify-1.13.0/beautify.min.js'],
  sourceEditorCDNUrlsJS: ['frameworks/ace-1.4.12/ace.js'],
  colorPickerDefaultTab: 'color',
  height: '6.25rem',
  minHeight: 0,
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false,
  allowTabNavigation: false,
  processPasteFromWord: true,
  processPasteHTML: true,
  defaultActionOnPaste: 'insert_as_html',
  defaultActionOnPasteFromWord: 'insert_clear_html',
  cleanHTML: {
    denyTags: {
      a: {
        href: false
      },
      table: false,
      tr: false,
      td: false
    },
    replaceNBSP: true
  },
  controls: {
    ol: {
      icon: 'ol',
      command: 'insertOrderedList',
      tags: ['ol'],
      tooltip: 'Insert Ordered List',
      list: {
        default: 'Default',
        'lower-alpha': 'Lower Alpha',
        'upper-alpha': 'Upper Alpha'
      },
      exec:
        'function(e,t,n){var r=n.control,o="button"+r.command,a=r.args&&r.args[0]||i.dataBind(e,o);i.dataBind(e,o,a),e.execCommand(r.command,!1,a)}'
    }
  },
  placeholder: i18n.t('instanceEditor.textEditor.placeholder'),
  showCharsCounter: false,
  showWordsCounter: false,
  toolbarAdaptive: false,
  toolbar: false,
  showXPathInStatusbar: false,
  readonly: true,
  autofocus: false
};

const useStyles = makeStyles(() => ({
  borderWrap: {
    '& > .jodit-container': {
      borderTopLeftRadius: '0',
      borderTopRightRadius: '0'
    }
  },
  defaultData: {
    '& .jodit-workplace': {
      backgroundColor: '#fafad2'
    }
  }
}));

const VARIABLE_TYPE = {
  VARIABLE_CONTENT: 'variable-content',
  VARIABLE_CONTENT_UNIT: 'variable-content-unit',
  VARIABLE_CONTENT_LABEL: 'variable-content-label'
};

const VARIABLE_CONTENT_SEL =
  VARIABLE_TYPE.VARIABLE_CONTENT +
  ', ' +
  VARIABLE_TYPE.VARIABLE_CONTENT_LABEL +
  ', ' +
  VARIABLE_TYPE.VARIABLE_CONTENT_UNIT;

const DATA_CONTENT = {
  CUSTOM_FIELD: 'custom-field',
  FEATURE: 'feature',
  CLASSIFICATION: 'classification',
  CLASSIFICATION_SYSTEM: 'classification-system'
};
const DATA_PREFIX = 'data-';
const DATA_CATEGORY = {
  CUSTOM_FIELD: DATA_PREFIX + DATA_CONTENT.CUSTOM_FIELD,
  FEATURE: DATA_PREFIX + DATA_CONTENT.FEATURE,
  CLASSIFICATION: DATA_PREFIX + DATA_CONTENT.CLASSIFICATION,
  CLASSIFICATION_SYSTEM: DATA_PREFIX + DATA_CONTENT.CLASSIFICATION_SYSTEM
};

export const TextEditor = ({ textType, instanceEditor }) => {
  const { t } = useTranslation();
  const {
    getTextState,
    setTextState,
    isDefaultData,
    deleteDefaultData,
    setDefaultData,
    setBlockDefaultData,
    deleteBlockDefaultData,
    isBlockDefaultData,
    getCustomFieldState,
    getFeatureState,
    getVariableContentDefs,
    getFeatureDefs
  } = useDataManagement();
  const textEditorData = instanceEditor.textEditor[textType];
  const required = textEditorData?.required;
  const [textId, setTextId] = useState(textEditorData?.id);

  const defaultDataObject = {
    id: textType,
    type: 'textEditor',
    instanceId: instanceEditor.instanceId
  };

  const blockDefault = isBlockDefaultData(defaultDataObject);

  const classes = useStyles();
  const editor = useRef(null);

  const contentAutomation = Boolean(
    textEditorData?.defaultDataAwareInfo?.subscribedContentAutomation
  );

  if (contentAutomation) {
    const addTextVariableButtonName = 'addTextVariable';
    const addTextVariableButton = joditConfig.buttons.find(
      (button) =>
        typeof button === 'object' && button.name == addTextVariableButtonName
    );
    var addTextVariableButtonIndex = joditConfig.buttons.indexOf(
      addTextVariableButton
    );
    if (addTextVariableButtonIndex !== -1) {
      joditConfig.buttons.splice(addTextVariableButtonIndex, 1);
    }
    joditConfig.buttons.push({
      name: addTextVariableButtonName,
      text: t('instanceEditor.textVariable.buttonAdd'),
      exec: addTextVariable
    });

    const editTextVariableButtonName = 'editTextVariable';
    const editTextVariableButton = joditConfig.buttons.find(
      (button) =>
        typeof button === 'object' && button.name == editTextVariableButtonName
    );
    var editTextVariableButtonIndex = joditConfig.buttons.indexOf(
      editTextVariableButton
    );
    if (editTextVariableButtonIndex !== -1) {
      joditConfig.buttons.splice(editTextVariableButtonIndex, 1);
    }
    joditConfig.buttons.push({
      name: editTextVariableButtonName,
      text: t('instanceEditor.textVariable.buttonEdit'),
      exec: editTextVariable
    });

    const deleteTextVariableButtonName = 'deleteTextVariable';
    const deleteTextVariableButton = joditConfig.buttons.find(
      (button) =>
        typeof button === 'object' &&
        button.name == deleteTextVariableButtonName
    );
    var deleteTextVariableButtonIndex = joditConfig.buttons.indexOf(
      deleteTextVariableButton
    );
    if (deleteTextVariableButtonIndex !== -1) {
      joditConfig.buttons.splice(deleteTextVariableButtonIndex, 1);
    }
    joditConfig.buttons.push({
      name: deleteTextVariableButtonName,
      text: t('instanceEditor.textVariable.buttonDelete'),
      exec: deleteTextVariable
    });

    const prepareFormatVariableButtonName = 'prepareFormatVariable';
    const prepareFormatVariableButton = joditConfig.buttons.find(
      (button) =>
        typeof button === 'object' &&
        button.name == prepareFormatVariableButtonName
    );
    var prepareFormatVariableButtonIndex = joditConfig.buttons.indexOf(
      prepareFormatVariableButton
    );
    if (prepareFormatVariableButtonIndex !== -1) {
      joditConfig.buttons.splice(prepareFormatVariableButtonIndex, 1);
    }
    joditConfig.buttons.push({
      name: prepareFormatVariableButtonName,
      text: 'prepare',
      exec: prepareFormatVariable
    });
  }

  const [isOpen, setIsOpen] = useState(false);

  const [wrapperId, setWrapperId] = useState(null);

  const containerId = instanceEditor.instanceId;

  let text = getTextState(textId);
  let textHasVariables = false;
  if (text) {
    text = text.replace('<root>', '').replace('</root>', '');
    let $text = $(text);

    const parentClass = textEditorData?.parentObjectClass;
    const isPtdDefaultDataForArticle = containerId.endsWith(
      'PtdDefaultDataForArticle'
    );
    const variableContentDefs = getVariableContentDefs(parentClass);
    const customFieldDefs = variableContentDefs?.customFieldDefs;
    const featureDefs =
      parentClass === 'Article' || isPtdDefaultDataForArticle
        ? getFeatureDefs()
        : null;

    let $textVariables = $text.find(VARIABLE_CONTENT_SEL);
    $textVariables.each(function () {
      textHasVariables = true;
      let $textVariable = $(this);
      let variableContentString = null;
      let customFieldType = $textVariable.attr(DATA_CATEGORY.CUSTOM_FIELD);
      let featureId = $textVariable.attr(DATA_CATEGORY.FEATURE);
      let featureIdentifier = null;
      if (featureId) {
        featureIdentifier = {
          classificationIdentifier: $textVariable.attr(
            DATA_CATEGORY.CLASSIFICATION_SYSTEM
          ),
          classIdentifier: $textVariable.attr(DATA_CATEGORY.CLASSIFICATION),
          featureIdentifier: featureId
        };
      }

      if ($textVariable.is(VARIABLE_TYPE.VARIABLE_CONTENT)) {
        let variableValue = null;

        if (customFieldType) {
          variableValue = getCustomFieldState(containerId, customFieldType);
        } else if (featureId) {
          const featureKey = getFeatureKey(featureIdentifier);
          variableValue = getFeatureState(containerId, featureKey);
        }

        variableContentString = getVariableValueforText(variableValue);
      } else if (
        $textVariable.is(VARIABLE_TYPE.VARIABLE_CONTENT_LABEL) ||
        $textVariable.is(VARIABLE_TYPE.VARIABLE_CONTENT_UNIT)
      ) {
        let targetDef = null;
        if (customFieldType && customFieldDefs) {
          targetDef = customFieldDefs.find(
            (customFieldDef) => customFieldDef.value === customFieldType
          );
        } else if (featureId && featureDefs) {
          targetDef = featureDefs.find(
            (featureDef) => featureDef.featureIdentifier === featureId
          );
        }
        if ($textVariable.is(VARIABLE_TYPE.VARIABLE_CONTENT_LABEL)) {
          if (customFieldType && targetDef) {
            variableContentString = targetDef.label;
          } else if (featureId && targetDef) {
            variableContentString = targetDef.featureHeader.text;
          }
        } else if ($textVariable.is(VARIABLE_TYPE.VARIABLE_CONTENT_UNIT)) {
          if (customFieldType && targetDef) {
            variableContentString = ''; // Units not yet implemented in custom fields
          } else if (featureId && targetDef) {
            variableContentString = targetDef.featureUnit;
          }
        }
      }
      if (variableContentString) {
        $textVariable.attr(
          'data-resolvedvariablecontent',
          variableContentString
        );
      }

      if (!$textVariable.find('.variableContentMeta').length) {
        const metaTitle = $textVariable.data('contentlabel');
        const $contentMeta = $('<span></span>', {
          class: 'variableContentMeta',
          text: metaTitle
        });
        $contentMeta.attr('contenteditable', 'false');
        $textVariable.append($contentMeta);
      }

      let isTextVariableLastElement = $textVariable.is(':last-child');
      if (isTextVariableLastElement) {
        const $spacer = $('<span class="variableContentSpacer">&nbsp;</span>');
        $textVariable.parent().append($spacer);
      }
    });

    let $textWrap = $('<div></div>');
    $textWrap.append($text);
    text = $textWrap.html();
  } else {
    text = '';
  }

  if (text.indexOf('<variable-content') == -1) {
    joditConfig.showPlaceholder = true;
  } else {
    joditConfig.showPlaceholder = false;
  }

  const [config, setConfig] = useState(joditConfig);

  function getVariableValueforText(variableValue) {
    if (variableValue == null || variableValue == undefined) {
      return '';
    }

    let variableContentString = '';
    if (typeof variableValue === 'object') {
      if (variableValue.selected) {
        let delimiter = '';
        variableValue.selected.forEach((selectedOption) => {
          variableContentString += delimiter + selectedOption.label;
          delimiter = '|';
        });
      } else if (
        variableValue.year &&
        variableValue.month &&
        variableValue.day
      ) {
        var date = new Date(
          variableValue.year,
          variableValue.month - 1,
          variableValue.day
        );
        variableContentString += date.toLocaleDateString(
          document.documentElement.lang
        );
      } else if (variableValue.lowerValue && variableValue.upperValue) {
        variableContentString +=
          getVariableValueforText(variableValue.lowerValue) +
          ' - ' +
          getVariableValueforText(variableValue.upperValue);
      }
    } else {
      variableContentString += variableValue;
    }

    return variableContentString;
  }

  const isDefaultDataPTD = isDefaultData(defaultDataObject);
  const hasDefaultData = Boolean(
    textEditorData?.defaultDataAwareInfo?.defaultDataAvailable
  );
  const [isError, setIsError] = useState(
    required && !text && !hasDefaultData ? true : false
  );
  const [errorMsg, setErrorMsg] = useState(
    required && !text && !hasDefaultData ? t('common.errorMandatory') : ''
  );

  var title = '';
  if (textEditorData.contentLangTitle) {
    if (textEditorData.contentLangTitle.missingContentLang) {
      title =
        '<' +
        textEditorData.contentLangTitle.typeName +
        ': ' +
        textEditorData.contentLangTitle.text +
        '>';
    } else {
      title = textEditorData.contentLangTitle.text;
    }
  }

  useEffect(() => {
    TOGO.popupManager.joditDialogOpen = false;

    let random = (Math.random() + 1).toString(36).substring(7);
    setWrapperId('textEditorWrapper' + random);
  }, []);

  if (!textEditorData) {
    return null;
  }

  function closeTextEditor() {
    $('.selectedTextVariable').removeClass('selectedTextVariable');

    setConfig((prevState) => ({
      ...prevState,
      showCharsCounter: false,
      showWordsCounter: false,
      toolbarAdaptive: false,
      toolbar: false,
      showXPathInStatusbar: false,
      height: '6.25rem',
      minHeight: 0,
      readonly: true,
      autofocus: false
    }));
    setIsOpen(false);
  }

  function showTextEditor(selectedWrapperId) {
    if (!isOpen) {
      setConfig((prevState) => ({
        ...prevState,
        showCharsCounter: true,
        showWordsCounter: true,
        toolbarAdaptive: false,
        toolbar: true,
        showXPathInStatusbar: true,
        height: 270,
        minHeight: 100,
        readonly: false,
        autofocus: true,
        events: {
          //beforeCommand: function (commandType) {
          beforeCommand: function () {
            if (!selectedWrapperId) return;

            let $wrapper = $('#' + selectedWrapperId);
            let $textVariableMeta = $wrapper.find('.variableContentMeta');
            $textVariableMeta.attr('contenteditable', true);

            // if (['deletebutton', 'backspacebutton'].includes(commandType)) { TODO: deleting should delete variables instantly
            //   $textVariableMeta.remove();
            // }
          },
          afterCommand: function () {
            if (!selectedWrapperId) return;

            let $wrapper = $('#' + selectedWrapperId);
            let $textVariableMeta = $wrapper.find('.variableContentMeta');
            $textVariableMeta.attr('contenteditable', false);

            // if (['deletebutton', 'backspacebutton'].includes(commandType)) {
            //   let $textVariables = $wrapper.find(VARIABLE_CONTENT_SEL);
            //   $textVariables.each(function () {
            //     let $textVariable = $(this);
            //     const metaTitle = $textVariable.data('contentlabel');
            //     const $contentMeta = $('<span></span>', {
            //       class: 'variableContentMeta',
            //       text: metaTitle
            //     });
            //     $contentMeta.attr('contenteditable', 'false');
            //     $textVariable.append($contentMeta);
            //   });
            // }
          }
        }
      }));
      setIsOpen(true);
    }

    if (!selectedWrapperId) return;
    let $wrapper = $('#' + selectedWrapperId);

    $wrapper.off().on('click', '.variableContentMeta', function () {
      //  VARIABLE_CONTENT_SEL
      $('.selectedTextVariable').removeClass('selectedTextVariable');

      const $thisSelectedTextVariable = $(this).parent();
      $thisSelectedTextVariable.addClass('selectedTextVariable');
      TOGO.Components.$selectedTextVariable = $thisSelectedTextVariable;

      $wrapper.find('.jodit-toolbar-button_editTextVariable').show();
      $wrapper.find('.jodit-toolbar-button_deleteTextVariable').show();
      $wrapper.find('.jodit-toolbar-button_addTextVariable').hide();

      let $tempContentSpacer = $wrapper.find('.tempContentSpacer');
      if ($tempContentSpacer.length) {
        let $tempContentSpacerContent = $tempContentSpacer.children();
        if ($tempContentSpacerContent.length) {
          $tempContentSpacer.after($tempContentSpacerContent);
        }
        $tempContentSpacer.remove();
      }

      const $prepareFormatButton = $wrapper.find(
        '.jodit-toolbar-button_prepareFormatVariable > .jodit-toolbar-button__button'
      );
      $prepareFormatButton.click();
    });

    $wrapper.on('click', '.jodit-wysiwyg', function (event) {
      let $clickedElement = $(event.target);
      if ($clickedElement.is('.variableContentMeta')) {
        return false;
      }
      TOGO.Components.$selectedTextVariable = null;
      $('.selectedTextVariable').removeClass('selectedTextVariable');

      $wrapper.find('.jodit-toolbar-button_addTextVariable').show();
      $wrapper.find('.jodit-toolbar-button_deleteTextVariable').hide();
      $wrapper.find('.jodit-toolbar-button_editTextVariable').hide();

      let $tempContentSpacer = $wrapper.find('.tempContentSpacer');
      if ($tempContentSpacer.length) {
        let $tempContentSpacerContent = $tempContentSpacer.children();
        if ($tempContentSpacerContent.length) {
          $tempContentSpacer.after($tempContentSpacerContent);
        }
        $tempContentSpacer.remove();
      }
    });
  }

  function prepareFormatVariable(editor) {
    let selection = editor.selection;

    let $selectedVariable = TOGO.Components.$selectedTextVariable;
    const $spacerStart = $('<span class="tempContentSpacer">&nbsp;</span>');
    const $spacerEnd = $('<span class="tempContentSpacer">&nbsp;</span>');
    $selectedVariable.before($spacerStart);
    $selectedVariable.after($spacerEnd);

    let selectionRange = document.createRange();
    selectionRange.setStart($spacerStart[0], 0);
    selectionRange.setEnd($spacerEnd[0], 0);
    selection.selectRange(selectionRange);
  }

  function editTextVariable(editor) {
    const $selectedVariable = TOGO.Components.$selectedTextVariable;
    if (!$selectedVariable || !$selectedVariable.length) return;

    let dataCategory = null;
    const tagName = $selectedVariable.prop('tagName').toLowerCase();
    const customField =
      $selectedVariable.attr(DATA_CATEGORY.CUSTOM_FIELD) || null;
    const feature = $selectedVariable.attr(DATA_CATEGORY.FEATURE) || null;
    const classification =
      $selectedVariable.attr(DATA_CATEGORY.CLASSIFICATION) || null;
    const classificationSystem =
      $selectedVariable.attr(DATA_CATEGORY.CLASSIFICATION_SYSTEM) || null;

    if (customField) {
      dataCategory = DATA_CATEGORY.CUSTOM_FIELD;
    } else if (feature) {
      dataCategory = DATA_CATEGORY.FEATURE;
    }

    selectTextVariable(editor, $selectedVariable, {
      dataCategory: dataCategory,
      customField: customField,
      feature: feature,
      classification: classification,
      classificationSystem: classificationSystem,
      tagName: tagName
    });
  }

  function addTextVariable(editor) {
    selectTextVariable(editor, null, {
      dataCategory: DATA_CATEGORY.CUSTOM_FIELD,
      customField: null,
      feature: null,
      classification: null,
      classificationSystem: null,
      tagName: null
    });
  }

  function deleteTextVariable() {
    if (!contentAutomation) return;

    const $selectedVariable = TOGO.Components.$selectedTextVariable;
    if (!$selectedVariable || !$selectedVariable.length) return;

    if ($selectedVariable) {
      $selectedVariable.remove();
    }
  }

  function selectTextVariable(editor, $selectedTextVariable, variableSetup) {
    if (!contentAutomation) return;

    const parentClass = textEditorData?.parentObjectClass;
    const variableContentDefs = getVariableContentDefs(parentClass);
    if (!variableContentDefs) return;

    const isPtdDefaultDataForArticle = containerId.endsWith(
      'PtdDefaultDataForArticle'
    );
    const customFields = variableContentDefs.customFieldDefs;
    const featureDefs =
      parentClass === 'Article' || isPtdDefaultDataForArticle
        ? getFeatureDefs()
        : null;

    TOGO.popupManager.joditDialogOpen = true;
    const dialog = new Jodit.modules.Dialog();

    const $dialogContent = $('<div></div>');
    const $wrap = $('<div></div>', { id: 'textVariableDialog' });

    const $dataCategoryWrap = $('<div></div>', {
      class: 'textVariableSelectWrap textVariableSelectDataCategoryWrap'
    });
    const $dataCategoryTitle = $('<h4></h4>', {
      text: t('instanceEditor.textVariable.dataCategory')
    });
    $dataCategoryWrap.append($dataCategoryTitle);
    const $dataCategorySelect = $('<select></select>', {
      id: 'textVariableSelectDataCategory',
      class: 'selectpicker'
    });
    $dataCategorySelect.attr('title', '-');
    const $customFieldsOption = $('<option></option>', {
      value: DATA_CATEGORY.CUSTOM_FIELD,
      text: t('data.customFields')
    });
    if (DATA_CATEGORY.CUSTOM_FIELD === variableSetup.dataCategory) {
      $customFieldsOption.attr('selected', 'selected');
    }
    $dataCategorySelect.append($customFieldsOption);
    $dataCategoryWrap.append($dataCategorySelect);
    if (featureDefs?.length) {
      const $featuresOption = $('<option></option>', {
        value: DATA_CATEGORY.FEATURE,
        text: t('data.features')
      });
      if (DATA_CATEGORY.FEATURE === variableSetup.dataCategory) {
        $featuresOption.attr('selected', 'selected');
      }
      $dataCategorySelect.append($featuresOption);
    } else {
      $dataCategoryWrap.addClass('hidden');
    }
    $wrap.append($dataCategoryWrap);

    var dataCategoryPossible = false;

    if (customFields?.length) {
      const $customFieldsWrap = $('<div></div>', {
        class: 'textVariableSelectWrap textVariableSelectCustomFieldWrap hidden'
      });
      if (variableSetup.dataCategory === DATA_CATEGORY.CUSTOM_FIELD) {
        $customFieldsWrap.removeClass('hidden');
      }
      const $customFieldsTitle = $('<h4></h4>', {
        text: t('data.customFields')
      });
      $customFieldsWrap.append($customFieldsTitle);
      const $customFieldSelect = $('<select></select>', {
        id: 'textVariableSelectCustomField',
        class: 'selectpicker'
      });
      $customFieldSelect.attr('title', '-');
      customFields.forEach((customField) => {
        const $customFieldOption = $('<option></option>', {
          value: customField.value,
          text: customField.label
        });
        if (customField.value === variableSetup.customField) {
          $customFieldOption.attr('selected', 'selected');
        }
        $customFieldSelect.append($customFieldOption);

        dataCategoryPossible = true;
      });
      $customFieldsWrap.append($customFieldSelect);
      $wrap.append($customFieldsWrap);
    }

    if (featureDefs?.length) {
      const $featuresWrap = $('<div></div>', {
        class: 'textVariableSelectWrap textVariableSelectFeatureWrap hidden'
      });
      if (variableSetup.dataCategory === DATA_CATEGORY.FEATURE) {
        $featuresWrap.removeClass('hidden');
      }
      const $featuresTitle = $('<h4></h4>', {
        text: t('data.features')
      });
      $featuresWrap.append($featuresTitle);
      const $featuresSelect = $('<select></select>', {
        id: 'textVariableSelectFeature',
        class: 'selectpicker'
      });
      $featuresSelect.attr('title', '-');
      featureDefs.forEach((featureDef) => {
        const $featureOption = $('<option></option>', {
          value: featureDef.featureIdentifier,
          text: featureDef.featureHeader.text
        })
          .attr(
            DATA_CATEGORY.CLASSIFICATION_SYSTEM,
            featureDef.classificationIdentifier
          )
          .attr(DATA_CATEGORY.CLASSIFICATION, featureDef.classIdentifier);
        if (featureDef.featureIdentifier === variableSetup.feature) {
          $featureOption.attr('selected', 'selected');
        }
        $featuresSelect.append($featureOption);

        dataCategoryPossible = true;
      });
      $featuresWrap.append($featuresSelect);
      $wrap.append($featuresWrap);
    }

    if (!dataCategoryPossible) {
      TOGO.Util.notifyResponse(
        t('instanceEditor.textVariable.dataCategoryNotPossible'),
        true
      );

      TOGO.popupManager.joditDialogOpen = false;
      return;
    }

    const $tagNameWrap = $('<div></div>', {
      class: 'textVariableSelectWrap textVariableSelectTagNameWrap'
    });
    const $tagNameTitle = $('<h4></h4>', {
      text: t('instanceEditor.textVariable.tagName')
    });
    $tagNameWrap.append($tagNameTitle);
    const $tagNameSelect = $('<select></select>', {
      id: 'textVariableSelectTagName',
      class: 'selectpicker'
    });
    $tagNameSelect.attr('title', '-');

    const $tagNameLabelOption = $('<option></option>', {
      value: VARIABLE_TYPE.VARIABLE_CONTENT_LABEL,
      text: t('instanceEditor.textVariable.label')
    });
    if (VARIABLE_TYPE.VARIABLE_CONTENT_LABEL === variableSetup.tagName) {
      $tagNameLabelOption.attr('selected', 'selected');
    }
    $tagNameSelect.append($tagNameLabelOption);

    const $tagNameValueOption = $('<option></option>', {
      value: VARIABLE_TYPE.VARIABLE_CONTENT,
      text: t('instanceEditor.textVariable.value')
    });
    if (VARIABLE_TYPE.VARIABLE_CONTENT === variableSetup.tagName) {
      $tagNameValueOption.attr('selected', 'selected');
    }
    $tagNameSelect.append($tagNameValueOption);

    const $tagNameUnitOption = $('<option></option>', {
      value: VARIABLE_TYPE.VARIABLE_CONTENT_UNIT,
      text: t('instanceEditor.textVariable.unit')
    });
    if (VARIABLE_TYPE.VARIABLE_CONTENT_UNIT === variableSetup.tagName) {
      $tagNameUnitOption.attr('selected', 'selected');
    }
    $tagNameSelect.append($tagNameUnitOption);
    $tagNameWrap.append($tagNameSelect);
    $wrap.append($tagNameWrap);

    const $acceptButtonWrap = $('<div></div>', {
      class: 'textVariableAcceptButtonWrap'
    });
    const $acceptButton = $('<button></button>', {
      id: 'textVariableAcceptButton',
      class: 'btn btn-primary'
    });
    $acceptButton.text('OK');
    $acceptButtonWrap.append($acceptButton);
    $wrap.append($acceptButtonWrap);

    $dialogContent.append($wrap);
    const contentAsHtml = editor.create.fromHTML($dialogContent.html());

    if ($selectedTextVariable) {
      dialog.setHeader(t('instanceEditor.textVariable.buttonEdit'));
    } else {
      dialog.setHeader(t('instanceEditor.textVariable.buttonAdd'));
    }

    dialog.setContent(contentAsHtml);
    dialog.destroyAfterClose = true;

    dialog.open();

    $(dialog.dialog)
      .find('.jodit-dialog__header-toolbar')
      .on('click', function () {
        TOGO.popupManager.joditDialogOpen = false;
      });

    const $textVariableDialog = $('#textVariableDialog');
    TOGO.initializer.initialize($textVariableDialog);

    $textVariableDialog
      .find('#textVariableSelectDataCategory')
      .on('change', function () {
        const $select = $(this);
        const dataCategory = $select.val();
        variableSetup.dataCategory = dataCategory;

        const $selectCustomFieldWrap = $textVariableDialog.find(
          '.textVariableSelectCustomFieldWrap'
        );
        const $selectFeatureWrap = $textVariableDialog.find(
          '.textVariableSelectFeatureWrap'
        );

        if (dataCategory === DATA_CATEGORY.CUSTOM_FIELD) {
          $selectCustomFieldWrap.removeClass('hidden');
          $selectFeatureWrap.addClass('hidden');
        } else if (dataCategory === DATA_CATEGORY.FEATURE) {
          $selectFeatureWrap.removeClass('hidden');
          $selectCustomFieldWrap.addClass('hidden');
        }

        $selectCustomFieldWrap.find('select').selectpicker('val', '');
        $selectFeatureWrap.find('select').selectpicker('val', '');
        variableSetup.customField = null;
        variableSetup.feature = null;
      });

    $textVariableDialog
      .find('#textVariableSelectCustomField')
      .on('change', function () {
        const $select = $(this);
        variableSetup.customField = $select.val();
        variableSetup.feature = null;
      });

    $textVariableDialog
      .find('#textVariableSelectFeature')
      .on('change', function () {
        const $select = $(this);
        variableSetup.feature = $select.val();
        variableSetup.customField = null;
      });

    $textVariableDialog
      .find('#textVariableAcceptButton')
      .on('click', function () {
        const $tagNameSelection = $textVariableDialog.find(
          '#textVariableSelectTagName'
        );
        const $selectedTagName = $tagNameSelection.find('option:selected');
        variableSetup.tagName = $tagNameSelection.val();
        if (!variableSetup.tagName) {
          return false;
        }

        const $createTextVariable = $('<' + variableSetup.tagName + '/>');

        let $selectedData;
        let metaTitle;
        if (variableSetup.customField) {
          $selectedData = $textVariableDialog
            .find('#textVariableSelectCustomField')
            .find('option:selected');
          metaTitle = t('data.customField') + ': ';

          $createTextVariable.attr(
            'data-custom-field',
            variableSetup.customField
          );
        } else if (variableSetup.feature) {
          $selectedData = $textVariableDialog
            .find('#textVariableSelectFeature')
            .find('option:selected');
          metaTitle = t('data.feature') + ': ';

          $createTextVariable
            .attr('data-feature', variableSetup.feature)
            .attr(
              DATA_CATEGORY.CLASSIFICATION,
              $selectedData.attr(DATA_CATEGORY.CLASSIFICATION)
            )
            .attr(
              DATA_CATEGORY.CLASSIFICATION_SYSTEM,
              $selectedData.attr(DATA_CATEGORY.CLASSIFICATION_SYSTEM)
            );
        } else {
          return false;
        }

        if ($selectedTextVariable) {
          $selectedTextVariable.remove();
        }

        metaTitle += $selectedData.text();
        metaTitle += '/';
        metaTitle += $selectedTagName.text();

        $createTextVariable.attr('data-contentlabel', metaTitle);

        const $contentMeta = $('<span></span>', {
          class: 'variableContentMeta',
          text: metaTitle
        });
        $contentMeta.attr('contenteditable', 'false');
        $createTextVariable.append($contentMeta);

        const $wrapVariable = $('<span></span>');
        $wrapVariable.append($createTextVariable);

        let textVariableString = $wrapVariable.html();
        editor.s.insertHTML(textVariableString);

        TOGO.popupManager.joditDialogOpen = false;
        dialog.close();
      });
  }

  function onChangeText(newTextValue) {
    if (TOGO.popupManager.joditDialogOpen || instanceEditor.readOnly) {
      return;
    }

    if (newTextValue) {
      let $newTextValue = $(newTextValue);
      let $variableContentSpacer = $newTextValue.find('.variableContentSpacer');
      if ($variableContentSpacer.length) {
        if ($variableContentSpacer.text().trim().length) {
          $variableContentSpacer.removeClass('variableContentSpacer');
        } else if ($variableContentSpacer.is(':last-child')) {
          $variableContentSpacer.remove();
        }
      }

      let $textWrap = $('<div></div>');
      $textWrap.append($newTextValue);
      newTextValue = $textWrap.html();
    } else {
      newTextValue = null;
    }

    if (newTextValue) {
      newTextValue = newTextValue
        .replaceAll('<br>', '<br />')
        .replaceAll('</br>', '')
        .replaceAll('<br /></', '</');

      newTextValue = newTextValue.replace(/<div><br \/><\/div>/g, '');
      newTextValue = newTextValue.replace(/(&nbsp;){1}/g, ' ');
      newTextValue = newTextValue.replace(/(\u00a0){1}/g, ' ');
    }

    if (
      newTextValue === '<div><br /></div>' ||
      newTextValue === '<div></div>'
    ) {
      newTextValue = null;
    }

    if (required && !newTextValue && !hasDefaultData) {
      setIsError(true);
      setErrorMsg(t('common.errorMandatory'));
      TOGO.Util.notifyResponse(t('common.errorMandatory'), true);
      return;
    }

    closeTextEditor();

    setErrorMsg('');
    setIsError(false);

    if (text === newTextValue || (!text && !newTextValue)) {
      return;
    }

    const payload = {
      id: textId,
      language: textEditorData.language,
      parentId: textEditorData.parentId,
      parentObjectClass: textEditorData.parentObjectClass,
      defaultDataForObjectClass: textEditorData.defaultDataForObjectClass,
      text: newTextValue,
      textType: textEditorData.textType,
      textHasVariables: textHasVariables
    };

    if (isDefaultDataPTD || blockDefault) {
      payload.id = null;
    }

    ajaxPost('saveText', {}, payload).then((ajaxData) => {
      let response = ajaxData.response;
      if (response)
        TOGO.Util.notifyResponse(response.message, !response.success);

      let targetTextId;

      if (!ajaxData.error && response) {
        if (newTextValue === null) {
          // text instance was deleted:
          let textInstanceId = response.textInstanceId;
          let defaultText = response.defaultText;
          if (textInstanceId && defaultText) {
            setDefaultData(defaultDataObject);

            targetTextId = textInstanceId;
            setTextId(textInstanceId);
            setTextState(targetTextId, defaultText);
          } else {
            setTextId(null);
            setTextState(textId, null);
          }
        } else {
          targetTextId = textId;
          if (textId === null || isDefaultDataPTD) {
            setTextId(response.textInstanceId);
            targetTextId = response.textInstanceId;
          }
          resetBlockDefaultDataText(true);
          deleteDefaultData(defaultDataObject);
          setTextState(targetTextId, newTextValue);
        }
      }
    });
  }

  function setBlockDefaultDataText() {
    let contentIdentifier = defaultDataObject.id;
    let type = defaultDataObject.type;
    let parentId = textEditorData.parentId;
    let parentObjectClass = textEditorData.parentObjectClass;

    ajaxPost('dataManagement/postSetBlockDefaultData', {
      contentIdentifier: contentIdentifier,
      type: type,
      parentId: parentId,
      parentObjectClass: parentObjectClass
    }).then((ajaxData) => {
      let msg = ajaxData.response?.message || ajaxData.error;
      TOGO.Util.notifyResponse(msg, Boolean(ajaxData.error));
      if (ajaxData.error) return;
    });
    setBlockDefaultData(defaultDataObject);
    deleteDefaultData(defaultDataObject);
    setTextState(textId, null);
  }

  function resetBlockDefaultDataText(withOutReset) {
    let contentIdentifier = defaultDataObject.id;
    let type = defaultDataObject.type;
    let parentId = textEditorData.parentId;
    let parentObjectClass = textEditorData.parentObjectClass;

    ajaxPost('dataManagement/postResetBlockDefaultData', {
      contentIdentifier: contentIdentifier,
      type: type,
      parentId: parentId,
      parentObjectClass: parentObjectClass
    }).then((ajaxData) => {
      let msg = ajaxData.response?.message || ajaxData.error;
      TOGO.Util.notifyResponse(msg, Boolean(ajaxData.error));
      if (ajaxData.error) return;
    });
    deleteBlockDefaultData(defaultDataObject);
    if (!withOutReset) {
      setDefaultData(defaultDataObject);
      resetDefaultText();
    }
  }

  function resetDefaultText() {
    let contentIdentifier = defaultDataObject.id;
    let contentLanguage = textEditorData.language;
    let parentId = textEditorData.parentId;
    let parentObjectClass = textEditorData.parentObjectClass;

    ajaxGet('getDefaultText', {
      contentIdentifier: contentIdentifier,
      contentLanguage: contentLanguage,
      parentId: parentId,
      parentObjectClass: parentObjectClass
    }).then((ajaxData) => {
      let msg = ajaxData.response?.message || ajaxData.error;
      TOGO.Util.notifyResponse(msg, Boolean(ajaxData.error));
      if (ajaxData.error) return;
      let textInstanceId = ajaxData.response.textInstanceId;
      let defaultText = ajaxData.response.defaultText;
      if (textInstanceId && defaultText) {
        setTextId(textInstanceId);

        let targetTextId = textInstanceId;
        setTextState(targetTextId, defaultText);
      }
    });
  }

  if (!textEditorData) {
    return null;
  }
  return (
    <>
      <FormControl
        error={isError}
        className="instanceEditorComponent"
        fullWidth={true}
      >
        <div style={{ maxWidth: 'calc(100vw - 53rem)' }}>
          <div className={`textEditor ${isOpen ? 'editMode' : 'readingMode'}`}>
            <div
              className="textEditorTitle editorTitle"
              style={{
                height: '35px',
                minHeight: '35px !important'
              }}
            >
              <h2>
                <span>{title}</span>
              </h2>
              {(isDefaultDataPTD || blockDefault) && (
                <ToggleStandardValues
                  switchMediaTextEditor={true}
                  setBlockDefaultData={setBlockDefaultDataText}
                  resetBlockDefaultData={resetBlockDefaultDataText}
                  isBlockDefaultData={blockDefault}
                />
              )}
            </div>
            <div
              id={wrapperId}
              className={`${classes.borderWrap} ${
                isDefaultDataPTD ? classes.defaultData : ''
              }`}
              onClick={() => {
                if (!instanceEditor.readOnly) {
                  showTextEditor(wrapperId);
                }
              }}
            >
              <JoditEditor
                className={classes.dontShow}
                ref={editor}
                value={text}
                config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={(newContent, e) => onChangeText(newContent, e)}
              />
            </div>
            <FormHelperText>{errorMsg}</FormHelperText>
          </div>
        </div>
      </FormControl>
    </>
  );
};

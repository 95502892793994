import React from 'react';

import { Box } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import { useDataManagement } from '../../datamanagement/DataManagementContext';
import { TitleDisplay } from '../tools/TitleDisplay';
import { ContainerDisplay } from '../tools/ContainerDisplay';
import { AccordionDisplay } from '../tools/AccordionDisplay';
import { TabsDisplay } from '../tools/TabsDisplay';
import { SingleInstanceTableDisplay } from './SingleInstanceTableDisplay';
import { MultipleInstance } from './MultipleInstance';
import { TextEditor } from '../../datamanagement/TextEditor';
import { TextEditorSimple } from '../../datamanagement/TextEditorSimple';
import { MediaEditor } from '../../datamanagement/MediaEditor';
import { CustomFieldEditor } from '../../datamanagement/CustomFieldEditor';
import { GeneralFieldEditor } from '../../datamanagement/GeneralFieldEditor';
import { KeywordsEditor } from '../../datamanagement/KeywordsEditor';
import { BulkDataTypes as FieldBulkDataTypes } from '../tools/FieldEditorSelector';
import { BulkDataTypes as TextBulkDataTypes } from '../tools/TextEditorSelector';
import { BulkDataTypes as MediumBulkDataTypes } from '../tools/MediaEditorSelector';
import { CREATE_INSTANCE_ID } from '../../datamanagement/create-instances/CreateInstance';

import {
  ArticleFeatureEditor,
  getFeatureKey
} from '../../datamanagement/ArticleFeatureEditor';

const useContainerStyles = makeStyles(() => ({
  two: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 'calc(100vw - 53rem)',
    '& > *': {
      flex: '1 0 100%'
    },
    '& > .instanceEditorComponent': {
      flex: '1 0 48% !important',
      margin: '1rem 5px'
    }
  }
}));

const TabEditorDisplayNode = ({ node, nodesContent, instanceEditor }) => {
  const {
    getVariableContentDefs,
    subscriptionInfo,
    userPermissions,
    articleDataZones
  } = useDataManagement();

  const classes = useContainerStyles();
  let typeName = '';
  if (typeof node.type === 'object') {
    typeName = node.type.resolvedName;
  } else {
    typeName = node.type;
  }

  const isMultipleData = node.props.isMultipleData;
  const dataSource = node.props.dataSource;

  if (
    isMultipleData &&
    !dataSource.singleData &&
    instanceEditor.instanceType != dataSource.source
  ) {
    return (
      <MultipleInstance
        parentNode={node}
        parentNodeTypeName={typeName}
        nodesContent={nodesContent}
        parentInstanceEditor={instanceEditor}
        targetInstanceType={node.props.dataSource.source}
      ></MultipleInstance>
    );
  }

  const Children = node.nodes.map((childKey) => getChildNodeElement(childKey));

  const LinkedChildren = {};
  for (let elementCanvasId in node.linkedNodes) {
    const linkedChildKey = node.linkedNodes[elementCanvasId];
    const nodeElement = getChildNodeElement(linkedChildKey);
    LinkedChildren[elementCanvasId] = nodeElement;
  }

  function getChildNodeElement(childKey) {
    return (
      <TabEditorDisplayNode
        key={childKey}
        node={nodesContent[childKey]}
        nodesContent={nodesContent}
        instanceEditor={instanceEditor}
      />
    );
  }

  if (node.props.bulkDataType) {
    return (
      <BulkDataNodes
        bulkDataType={node.props.bulkDataType}
        instanceEditor={instanceEditor}
        nodeProps={node.props}
      />
    );
  }

  switch (typeName) {
    case 'Container':
      return <ContainerDisplay {...node.props}>{Children}</ContainerDisplay>;
    case 'Tabs': {
      let tabsInNode = JSON.parse(JSON.stringify(node.props.tabs));
      let tabs = tabsInNode
        .filter((tab) => tab)
        .map((tab) => {
          tab.content = LinkedChildren[tab.id];
          return tab;
        });

      const variableContentDefs = getVariableContentDefs(
        instanceEditor.instanceType
      );

      tabs = tabs.filter((tab) => {
        if (tab.dataReq) {
          if (
            !checkTabDataRequirement(tab, instanceEditor, variableContentDefs)
          )
            return false;
        }
        if (tab.subscriptionReq) {
          const subFeatureList = subscriptionInfo?.subscriptionBasicFeatureList;
          if (!subFeatureList) return false;

          for (let index = 0; index < tab.subscriptionReq.length; index++) {
            const req = tab.subscriptionReq[index];
            if (
              !subFeatureList.some(
                (item) => item.value && item.featureIdentifier === req
              )
            ) {
              return false;
            }
          }
        }
        if (tab.permissionReq) {
          if (!userPermissions) return false;

          for (let index = 0; index < tab.permissionReq.length; index++) {
            const req = tab.permissionReq[index];

            if (req === 'ROLE_ADJUST_PERMISSIONS_DASHBOARD') {
              if (articleDataZones) {
                if (!articleDataZones.length) return false;
              } else {
                return false;
              }
            }

            if (!userPermissions.includes(req)) {
              return false;
            }
          }
        }

        return true;
      });

      return (
        <TabsDisplay
          tabs={tabs}
          orientation={node.props.orientation}
          isShortVerticalTab={node.props.isShortVerticalTab}
        ></TabsDisplay>
      );
    }
    case 'TabContent':
      var containerFlexColumns = node.props.containerFlexColumns;
      return (
        <Box
          className={`tab-content ${
            containerFlexColumns === '2' ? classes.two : null
          }`}
        >
          {Children}
        </Box>
      );
    case 'Table': {
      const columns = node.props.columns?.map((column) => {
        column.content = LinkedChildren[column.dataId];
        return column;
      });
      if (!columns) return null;

      return (
        <SingleInstanceTableDisplay
          columns={columns}
          orientation={node.props.orientation}
        ></SingleInstanceTableDisplay>
      );
    }
    case 'TableCellContent':
      return <Box>{Children}</Box>;
    case 'Title':
      return <TitleDisplay {...node.props} />;
    case 'Accordion': {
      return <AccordionDisplay {...node.props}>{Children}</AccordionDisplay>;
    }
    case 'TextEditorSelector': {
      if (node.props.variant === 'formatted') {
        return (
          <TextEditor
            textType={node.props.textType}
            instanceEditor={instanceEditor}
          />
        );
      } else {
        return (
          <TextEditorSimple
            textType={node.props.textType}
            instanceEditor={instanceEditor}
          />
        );
      }
    }
    case 'MediaEditorSelector':
      return (
        <MediaEditor
          mediaType={node.props.mediaType}
          instanceEditor={instanceEditor}
        />
      );
    case 'FieldEditorSelector':
      if (node.props.generalFieldType === 'keyword') {
        return <KeywordsEditor instanceEditor={instanceEditor} />;
      } else if (node.props.generalFieldType) {
        return (
          <GeneralFieldEditor
            generalFieldType={node.props.generalFieldType}
            instanceEditor={instanceEditor}
          />
        );
      } else if (node.props.featureKey) {
        return (
          <ArticleFeatureEditor
            featureKey={node.props.featureKey}
            instanceEditor={instanceEditor}
          />
        );
      } else if (node.props.customFieldType) {
        const customFieldEditorData =
          instanceEditor.customFieldEditor[node.props.customFieldType];
        if (!customFieldEditorData) return null;

        return (
          <CustomFieldEditor
            customFieldType={node.props.customFieldType}
            instanceEditor={instanceEditor}
          />
        );
      } else {
        return null;
      }

    default:
      return <div className="EmptyTabEditorDisplayNode"></div>;
  }
};

export function checkTabDataRequirement(
  tab,
  instanceEditor,
  variableContentDefs
) {
  if (!Object.keys(tab.dataReq).length) return true;

  for (let dataSource in tab.dataReq) {
    let curVariableContentDefs;
    if (dataSource === instanceEditor.instanceType) {
      curVariableContentDefs = variableContentDefs;
    } else {
      curVariableContentDefs =
        variableContentDefs.descendantInstanceData[dataSource];
      if (!curVariableContentDefs) continue;
    }

    let reqMet = checkDataSourceOfTabDataRequirement(
      tab,
      dataSource,
      curVariableContentDefs
    );
    if (reqMet) return true;
  }

  return false;
}

export function checkDataSourceOfTabDataRequirement(
  tab,
  dataSource,
  curVariableContentDefs
) {
  const sourceReq = tab.dataReq[dataSource];

  const textTypes = curVariableContentDefs.textDefs.map(
    (textDef) => textDef.value
  );
  const textReqs = sourceReq.Text;
  if (textReqs) {
    for (let textReq in textReqs) {
      if (textReq === 'bulkDataType_texts' && textTypes.length) {
        return true;
      } else if (textTypes.includes(textReq)) {
        return true;
      } else {
        let textGroup = textReq.split('bulkDataType_')[1];
        if (textGroup && textGroup.startsWith(TextBulkDataTypes.texts_group)) {
          textGroup = textGroup.split(TextBulkDataTypes.texts_group)[1];
          const textGroupDefs = curVariableContentDefs.textDefs.filter(
            (textDef) => textDef.groupId === textGroup
          );
          if (textGroupDefs.length > 0) return true;
        }
      }
    }
  }

  const mediumTypes = curVariableContentDefs.mediumDefs.map(
    (mediumDef) => mediumDef.value
  );
  const mediumReqs = sourceReq.Medium;
  if (mediumReqs) {
    for (let mediumReq in mediumReqs) {
      if (mediumReq === 'bulkDataType_media' && mediumTypes.length) {
        return true;
      } else if (mediumTypes.includes(mediumReq)) {
        return true;
      } else {
        let mediaGroup = mediumReq.split('bulkDataType_')[1];
        if (
          mediaGroup &&
          mediaGroup.startsWith(MediumBulkDataTypes.media_group)
        ) {
          mediaGroup = mediaGroup.split(MediumBulkDataTypes.media_group)[1];
          const mediaGroupDefs = curVariableContentDefs.mediumDefs.filter(
            (mediumDef) => mediumDef.groupId === mediaGroup
          );
          if (mediaGroupDefs.length > 0) return true;
        }
      }
    }
  }

  const customFieldTypes = curVariableContentDefs.customFieldDefs.map(
    (customFieldDef) => customFieldDef.value
  );
  const customFieldReqs = sourceReq.CustomField;
  if (customFieldReqs) {
    for (let customFieldReq in customFieldReqs) {
      if (
        customFieldReq === 'bulkDataType_custom_fields' &&
        customFieldTypes.length
      ) {
        return true;
      } else if (customFieldTypes.includes(customFieldReq)) {
        return true;
      } else {
        let customFieldGroup = customFieldReq.split('bulkDataType_')[1];
        if (
          customFieldGroup &&
          customFieldGroup.startsWith(FieldBulkDataTypes.custom_fields_group)
        ) {
          customFieldGroup = customFieldGroup.split(
            FieldBulkDataTypes.custom_fields_group
          )[1];
          const customFieldGroupDefs = curVariableContentDefs.customFieldDefs.filter(
            (customFieldDef) => customFieldDef.groupId === customFieldGroup
          );
          if (customFieldGroupDefs.length > 0) return true;
        }
      }
    }
  }

  const generalFieldReqs = sourceReq.GeneralField;
  if (
    generalFieldReqs &&
    generalFieldReqs.keyword &&
    ['Product', 'Article'].includes(dataSource)
  ) {
    return true;
  }

  const featureReqs = sourceReq.Features;
  if (featureReqs) {
    for (let featureReq in featureReqs) {
      if (
        featureReq === 'bulkDataType_features' &&
        curVariableContentDefs.featuresExists
      ) {
        return true;
      }
    }
  }

  return false;
}

export const BulkDataNodes = ({ bulkDataType, instanceEditor, nodeProps }) => {
  const {
    getVariableContentDefs,
    getFeatureDefs,
    getCreationFeatureDefs
  } = useDataManagement();
  const variableContentDefs = getVariableContentDefs(
    instanceEditor.instanceType
  );

  //texts:
  if (
    bulkDataType === TextBulkDataTypes.texts ||
    bulkDataType.startsWith(TextBulkDataTypes.texts_group)
  ) {
    let textDefs = variableContentDefs.textDefs;

    if (bulkDataType != TextBulkDataTypes.texts) {
      let groupId = bulkDataType.split(TextBulkDataTypes.texts_group)[1];
      textDefs = textDefs.filter((def) => groupId == def.groupId);
    }

    if (nodeProps.variant === 'formatted') {
      return textDefs.map((textDef) => (
        <TextEditor
          key={textDef.value}
          textType={textDef.value}
          instanceEditor={instanceEditor}
          variant={nodeProps.variant}
        />
      ));
    } else if (nodeProps.variant === 'simple') {
      return textDefs.map((textDef) => (
        <TextEditorSimple
          key={textDef.value}
          textType={textDef.value}
          instanceEditor={instanceEditor}
          variant={nodeProps.variant}
        />
      ));
    } else {
      return null;
    }
  }
  //media:
  else if (
    bulkDataType === MediumBulkDataTypes.media ||
    bulkDataType.startsWith(MediumBulkDataTypes.media_group)
  ) {
    let mediumDefs = variableContentDefs.mediumDefs;

    if (bulkDataType != MediumBulkDataTypes.media) {
      let groupId = bulkDataType.split(MediumBulkDataTypes.media_group)[1];
      mediumDefs = mediumDefs.filter((def) => groupId == def.groupId);
    }

    return mediumDefs.map((mediumDef) => (
      <MediaEditor
        key={mediumDef.value}
        mediaType={mediumDef.value}
        instanceEditor={instanceEditor}
      />
    ));
  }
  //custom fields:
  else if (
    bulkDataType === FieldBulkDataTypes.custom_fields ||
    bulkDataType.startsWith(FieldBulkDataTypes.custom_fields_group)
  ) {
    let customFieldDefs = variableContentDefs.customFieldDefs;

    if (bulkDataType != FieldBulkDataTypes.custom_fields) {
      let groupId = bulkDataType.split(
        FieldBulkDataTypes.custom_fields_group
      )[1];
      customFieldDefs = customFieldDefs.filter((def) => groupId == def.groupId);
    }

    return customFieldDefs.map((customFieldDef) => (
      <CustomFieldEditor
        key={customFieldDef.value}
        customFieldType={customFieldDef.value}
        instanceEditor={instanceEditor}
      />
    ));
  }
  //general fields:
  else if (bulkDataType === FieldBulkDataTypes.general_fields) {
    const generalFieldDefs = variableContentDefs.generalFieldDefs;

    return generalFieldDefs
      .filter(
        (generalFieldDef) =>
          ![FieldBulkDataTypes.features, 'keyword'].includes(
            generalFieldDef.value
          )
      )
      .map((generalFieldDef) => {
        if (generalFieldDef.value === 'keyword') {
          return (
            <KeywordsEditor
              key={generalFieldDef.value}
              instanceEditor={instanceEditor}
            />
          );
        } else {
          return (
            <GeneralFieldEditor
              key={generalFieldDef.value}
              generalFieldType={generalFieldDef.value}
              instanceEditor={instanceEditor}
            />
          );
        }
      });
  }
  //article features:
  else if (bulkDataType === FieldBulkDataTypes.features) {
    let featureDefs;
    if (instanceEditor.instanceId === CREATE_INSTANCE_ID) {
      featureDefs = getCreationFeatureDefs();
    } else {
      featureDefs = getFeatureDefs();
    }

    return featureDefs.map((featureDef) => {
      const featureKey = getFeatureKey(featureDef);
      return (
        <ArticleFeatureEditor
          key={featureKey}
          featureKey={featureKey}
          instanceEditor={instanceEditor}
        />
      );
    });
  }
  // none:
  else {
    return null;
  }
};

export default TabEditorDisplayNode;

import React, { useState, useEffect } from 'react';
import Select from '@material-ui/core/Select';
import { MenuItem, Grid, Box, Icon, Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import Tooltip from '@material-ui/core/Tooltip';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import ajaxGet from 'src/services/ajaxGet';
import ajaxPost from 'src/services/ajaxPost';

import ajaxCall from 'src/services/ajaxCall.js';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  classification: {
    marginLeft: '0.6rem',
    '& .MuiInputBase-input': {
      fontSize: '1.5rem !important',
      maxMidth: '50rem !important',
      fontFamily: '"Roboto", sans-serif !important',
      minWidth: '20rem !important'
    }
  },
  pdfBtn: {
    color: '#F29528 !important',
    fontSize: '5rem !important'
  },
  pdfBtnDefault: {
    fontSize: '5rem !important'
  },
  favoriteInstanceWrap: {
    width: 50
  },
  favoriteInstanceBtn: {
    fontSize: '4rem !important'
  },
  infoBtn: {
    fontSize: '2rem !important'
  },
  deleteBtn: {
    color: 'rgb(237 14 14 / 89%) !important',
    fontSize: '2.4rem !important'
  },
  classificationTitle: {
    color: '#212529',
    fontSize: '1.5rem',
    fontWeight: 600
  },
  addAction: {
    color: 'white',
    backgroundColor: '#5cb85c !important',
    borderColor: '#5cb85c',

    '&:hover': {
      backgroundColor: '#458c45 !important'
    },
    minWidth: '0px',
    '& .MuiIcon-root': {
      fontSize: '1.4rem !important'
    }
  }
}));

export function ProductControl() {
  const [productTypeDefList, setPoductTypeDefList] = useState([]);
  const [productTypeDef, setProductTypeDef] = useState('');
  const [showSelectPTD, setShowSelectPTD] = useState(false);
  const [productDatasheetURL, setProductDatasheetURL] = useState('');
  const [
    dataSheetUrlRenderWithoutDefaultData,
    setDataSheetUrlRenderWithoutDefaultData
  ] = useState('');
  const [hasFeatureIndividualClc, setHasFeatureIndividualClc] = useState(false);
  const [isFavoriteInstance, setIsFavoriteInstance] = useState(false);

  const [hasContentAutomation, setHasContentAutomation] = useState(false);

  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    let $reactInstanceEditor = $('#react-InstanceEditor');
    let $instanceWrap = $reactInstanceEditor.closest('.instanceEditor');
    let objectClassName = $instanceWrap.data('classname');
    let instanceId = $instanceWrap.data('id');

    ajaxCall({
      httpMethod: 'get',
      url: 'favoriteInstance',
      params: {
        toggle: false,
        instanceId: instanceId,
        instanceClassName: objectClassName
      }
    }).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        setIsFavoriteInstance('favorite' == ajaxData.response.resultInfo);
      }
    });
  }, []);

  function handleChangePTD(event) {
    let val = event.target.value;
    let $reactInstanceEditor = $('#react-InstanceEditor');
    let $instanceWrap = $reactInstanceEditor.closest('.instanceEditor');
    let instanceId = $instanceWrap.data('id');
    let contentLanguage = $('#contentLangSelectElement').val() || null;

    var pojo = new Object();
    pojo.productId = instanceId;
    pojo.ptdId = val;
    pojo.contentLanguage = contentLanguage;

    ajaxPost('dataManagement/setProductTypeDefinition', pojo).then(
      (ajaxData) => {
        if (!ajaxData.error && ajaxData.response) {
          var ptdList = ajaxData.response.poductTypeDefList;
          setPoductTypeDefList(ptdList);
          location.reload();
        }
      }
    );
  }

  function getProductTypeDefList() {
    var params = new Object();
    var ptdId = $('#productPtdSelectButton').data('ptd-id') || null;
    let contentLanguage = $('#contentLangSelectElement').val() || null;

    let $reactInstanceEditor = $('#react-InstanceEditor');
    let $instanceWrap = $reactInstanceEditor.closest('.instanceEditor');
    let instanceId = $instanceWrap.data('id');

    if (ptdId != null) {
      setProductTypeDef(ptdId);
      setShowSelectPTD(true);
    } else {
      ptdId = '';
      setShowSelectPTD(false);
    }

    params.ptdId = ptdId;
    params.contentLanguage = contentLanguage;

    ajaxGet('dataManagement/getProductTypeDefinitionsSelect', params).then(
      (ajaxData) => {
        if (!ajaxData.error && ajaxData.response) {
          var res = ajaxData.response.productTypeDefinitions;
          setPoductTypeDefList(res);
        }
      }
    );

    var params_product = new Object();

    params_product.id = instanceId;
    params_product.contentLanguage = contentLanguage;
    ajaxGet('dataManagement/product', params_product).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        var res = ajaxData.response.productEditModel;
        if (res.dataSheetUrl != null && res.dataSheetUrl != '')
          setProductDatasheetURL(res.dataSheetUrl);
        if (
          res.dataSheetUrlRenderWithoutDefaultData != null &&
          res.dataSheetUrlRenderWithoutDefaultData != ''
        )
          setDataSheetUrlRenderWithoutDefaultData(
            res.dataSheetUrlRenderWithoutDefaultData
          );
        if (
          res.hasFeatureIndividualClc != null &&
          res.hasFeatureIndividualClc != ''
        )
        setHasContentAutomation(res.hasContentAutomation)
        setHasFeatureIndividualClc(res.hasFeatureIndividualClc);
      }
    });
  }

  useEffect(() => {
    getProductTypeDefList();
  }, [productTypeDef]);

  function resetPTD() {
    let $reactInstanceEditor = $('#react-InstanceEditor');
    let $instanceWrap = $reactInstanceEditor.closest('.instanceEditor');
    let instanceId = $instanceWrap.data('id');
    var pojo = new Object();
    pojo.productId = instanceId;

    ajaxPost('dataManagement/removeProductTypeDefinition', pojo).then(
      (ajaxData) => {
        if (!ajaxData.error && ajaxData.response) {
          location.reload();
        }
      }
    );
  }

  function openPTD() {
    setShowSelectPTD(true);
  }

  function toggleFavoriteInstance() {
    let $reactInstanceEditor = $('#react-InstanceEditor');
    let $instanceWrap = $reactInstanceEditor.closest('.instanceEditor');
    let objectClassName = $instanceWrap.data('classname');
    let instanceId = $instanceWrap.data('id');

    ajaxCall({
      httpMethod: 'post',
      url: 'favoriteInstance',
      params: {
        toggle: true,
        instanceId: instanceId,
        instanceClassName: objectClassName
      }
    }).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        if ('favorite' == ajaxData.response.resultInfo) {
          setIsFavoriteInstance(true);
        } else {
          setIsFavoriteInstance(false);
        }

        TOGO.Util.notify('', ajaxData.response.message, null, false);
      }
    });
  }

  return (
    <Box>
      <Grid container style={{ maxHeight: '4.5rem' }}>
        <Grid item style={{ maxHeight: '4.5rem' }} xs="auto">
          <span
            id="classificationTypeLabel"
            className={classes.classificationTitle}
          >
            {t('productType.Producttypdefinition')}
          </span>
          <Tooltip
            title={
              hasFeatureIndividualClc ? (
                <Typography fontSize={5}>
                  {t('productType.ptdSelectHelp')}:{' '}
                </Typography>
              ) : (
                <Typography fontSize={5}>
                  {t('productType.ptdSelectHelpNoSMLSubscription')}:
                </Typography>
              )
            }
          >
            <IconButton>
              <HelpOutlineIcon className={classes.infoBtn} />
            </IconButton>
          </Tooltip>
          {showSelectPTD ? (
            <>
              {productTypeDefList != null && productTypeDefList.length && (
                <Select
                  id="ptd-simple-select"
                  className={classes.classification}
                  onChange={handleChangePTD}
                  value={productTypeDef}
                  inputProps={{
                    sx: {
                      '&.MuiOutlinedInput-input:hover': {
                        border: '2px solid green'
                      }
                    }
                  }}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>{t('productType.select')}</em>;
                    }
                    if (productTypeDefList != null) {
                      let res = productTypeDefList.find(
                        (item) => item.id === selected
                      );
                      return res?.name?.text;
                    } else {
                      return '';
                    }
                  }}
                >
                  <MenuItem disabled value="">
                    <em>{t('productType.select')}</em>
                  </MenuItem>
                  {productTypeDefList != null &&
                    productTypeDefList.length &&
                    productTypeDefList.map((productTypeDefItem) => (
                      <MenuItem
                        key={productTypeDefItem.id}
                        value={productTypeDefItem.id}
                      >
                        {productTypeDefItem.name?.text}
                      </MenuItem>
                    ))}
                </Select>
              )}
            </>
          ) : (
            <Button onClick={openPTD} className={classes.addAction}>
              <Tooltip title={t('productType.open')} arrow>
                <Icon className="fa fa-plus" />
              </Tooltip>
            </Button>
          )}
          {showSelectPTD && (
            <Tooltip
              title={
                <Typography fontSize={5}> {t('productType.clear')}</Typography>
              }
              arrow
            >
              <IconButton
                onClick={() => {
                  resetPTD();
                }}
              >
                <CancelIcon fontSize="large" className={classes.deleteBtn} />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
        <Grid
          item
          style={{ marginTop: '-5rem' }}
          xs="auto"
          container
          justifyContent="flex-end"
        >
          <Tooltip
            title={
              <Typography fontSize={5}>{t('productType.datasheet')}</Typography>
            }
            arrow
          >
            <IconButton
              onClick={() => {
                window.open(productDatasheetURL);
              }}
            >
              <PictureAsPdf className={classes.pdfBtn} />
            </IconButton>
          </Tooltip>
          {dataSheetUrlRenderWithoutDefaultData != '' && hasContentAutomation && (
            <Tooltip
              title={
                <Typography fontSize={5}>
                  {t('productType.datasheetWithoutDefaultData')}
                </Typography>
              }
              arrow
            >
              <IconButton
                onClick={() => {
                  window.open(dataSheetUrlRenderWithoutDefaultData);
                }}
              >
                <PictureAsPdf className={classes.pdfBtnDefault} />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip
            title={
              <Typography fontSize={5}>
                {isFavoriteInstance
                  ? t('data.removeFavorite')
                  : t('data.addFavorite')}
              </Typography>
            }
            arrow
          >
            <IconButton
              onClick={toggleFavoriteInstance}
              className={classes.favoriteInstanceWrap}
            >
              <i
                className={`fa ${isFavoriteInstance ? 'fa-star' : 'fa-star-o'}  
                  ${classes.favoriteInstanceBtn}`}
                aria-hidden="true"
              ></i>
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Box>
  );
}

import React, { useState, useEffect, useMemo, createRef, useRef } from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ListItem, List } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Checkbox from "@material-ui/core/Checkbox";



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}


export default function FacetBox({filter, setFilter,updateSearch, facetElement, allSearchIds, changeAllSearchIds, checked, setChecked}) {
    
  const useStyles_ = makeStyles((theme) => ({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      position: "relative",
      overflow: "auto",
      maxHeight: 300,
    },
    root_: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      position: "relative",
      overflow: "auto",
      maxHeight: 227,
    },
    listSection: {
      backgroundColor: "inherit",
    },
    ul: {
      backgroundColor: "inherit",
      padding: 0,
    },
    checkboxStyle:{
        '&.Mui-checked':{
            color:' #f29528'
        }
    },
    tabs: {
        '& .MuiTabs-indicator': {
          backgroundColor: '#f29528',
          height: 5,
          top: 43
        },
        '& .MuiTab-textColorPrimary.Mui-selected': {
          opacity: 1,
          fontWeight: 'bold',
          color:'black',
          fontSize: '13px'
        }
    }
  }));

  const classes = useStyles_();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  // const [filter, setFilter] = React.useState([]);

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
    // setState({ ...state, [event.target.name]: event.target.checked });
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };


  const handleToggle = (value) => () => {
      const currentIndex = checked.findIndex(item => item?.searchIds[0] == value?.searchIds[0] && value?.valueAsString  == item?.valueAsString)
    // const currentIndex = checked.indexOf(value);
    checkfaceElement(value);
    const allSearchIdsIndex = allSearchIds.indexOf(value?.searchIds[0]);
    const newChecked = [...checked];

    // if (!checkfaceElement(value)) {
      if (currentIndex === -1) {
      newChecked.push(value);
      if(allSearchIdsIndex ===-1){
        changeAllSearchIds(value?.searchIds[0],true);
        addFilterValue(value, true);

      } else {
        // allSearchIds.splice(currentIndex, 1);
        addFilterValue(value, false);
      }
    } else {
      newChecked.splice(currentIndex, 1);
      // deleteFaceElement(value);
      deleteFilterValue(value, false);
    }
    setChecked(newChecked);
  };


  const deleteFaceElement = (value) =>{

  if (checked.length != 0){
    const currentIndex  = checked.findIndex(item =>item?.searchIds[0] === value?.searchIds[0] && value?.valueAsString  === item?.valueAsString)
    if (currentIndex != undefined ){
      checked.splice(currentIndex, 1);
      console.log(checked);
      setChecked(checked);
    }
  }
}
  const  deleteFilterValue = (item) =>{

    filter?.map((textSearchFilter, idx) => {
      if (textSearchFilter?.allSearchIds[0] === item?.searchIds[0]) {
        textSearchFilter.values.map((textSearch, index)=>{
          if (textSearch.value == item?.valueAsString){
            textSearchFilter.values.splice(index, 1);
            if(textSearchFilter.values.length == 0 ){
              filter.splice(idx, 1);
              changeAllSearchIds(item?.searchIds[0], false);
            }
          }
        })
      }
    });

    setFilter(filter);
    console.log(filter);
    updateSearch(filter);
  }

  const checkfaceElement = (value) =>{
    if (value == undefined ){
      return false;
    } 
    if (checked.length != 0){


      for (let i = 0; i < checked.length; i++) {
        if(checked[i]?.searchIds[0] === value?.searchIds[0] && value?.valueAsString  === checked[i]?.valueAsString){
          return true
        }
      }
      // for (var item in checked) {
      //   if(checked[item]?.searchIds[0] === value?.searchIds[0] && value?.valueAsString  === checked[item]?.valueAsString){
      //       return true
      //   }
      // }
      return false

    }else {
      return false
    }
  }

  const  addFilterValue = (item, bool) =>{
    if (bool) {
      var textSearchFilter = new Object();
      textSearchFilter.allSearchIds = item.searchIds;
      textSearchFilter.values = [];

      var  TextSearchFilterValue = new Object();
      TextSearchFilterValue.value= item?.valueAsString;
      TextSearchFilterValue.valueSearchIds=item.searchIds;
      textSearchFilter.values.push(TextSearchFilterValue);

      if ((textSearchFilter.allSearchIds[0].indexOf("feature_") != -1)){
        textSearchFilter.filterParentforChildren = true;
      }else{
        textSearchFilter.filterParentforChildren = false;
      }

      filter.push(textSearchFilter);
      setFilter(filter);
      console.log( filter);
      // console.log('allSearchIds : '+allSearchIds);
      updateSearch(filter);

    } else {

        filter?.map((textSearchFilter) => {
        if (textSearchFilter?.allSearchIds[0] === item?.searchIds[0]) {
          var  textSearchFilterValue = new Object();
          textSearchFilterValue.value= item?.valueAsString;
          textSearchFilterValue.valueSearchIds=item.searchIds;
          textSearchFilter.values.push(textSearchFilterValue);

          if ((textSearchFilter?.allSearchIds[0].indexOf("feature_") != -1)){
            textSearchFilterValue.filterParentforChildren = true;
          }else{
            textSearchFilterValue.filterParentforChildren = false;
          }

        }
      });
      setFilter(filter);
      console.log(filter);
      updateSearch(filter);
    }
  }

  return (
    <>
     { facetElement !=undefined && facetElement?.valueGroups !=undefined && 
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChangeTabs}
          aria-label="basic tabs example"
          className={classes.tabs}
        >
          {  facetElement?.valueGroups?.map((valueGroup, index) => (
            <Tab  key={index} label={valueGroup.label} {...a11yProps(index)} />
          ))}

        </Tabs>
      </Box>}
        { facetElement !=undefined  && facetElement?.valueGroups ? facetElement?.valueGroups?.map((valueGroup, idx) => (
        // <SwipeableViews
        //     key={idx}
        //     axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        //     index={value}
        //     onChangeIndex={handleChangeIndex}
        //   >
          <TabPanel value={value} key={idx}
           index={idx}>
            <List className={classes.root_}>

              {valueGroup?.values!=undefined && valueGroup?.values?.map((value,index) => {
                const labelId = `checkbox-list-label-${value?.valueLabel}`;
                return (
                  <ListItem
                    key={index}
                    role={undefined}
                    dense
                    button
                    onClick={handleToggle(value)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checkfaceElement(value)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                        className={classes.checkboxStyle}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={<Typography  fontSize={12} >{value.valueLabel +" ("+value.valueCount +")" } </Typography>}
                    />
                  </ListItem>
                );
              } )}
            </List>
          </TabPanel>
        // </SwipeableViews>
        )):(
            <List className={classes.root}>
              {facetElement.values!=undefined && facetElement?.values?.map((value,index) => {
                const labelId = `checkbox-list-label-${value}`;
                return (
                  <ListItem
                    key={index}
                    role={undefined}
                    dense
                    button
                    onClick={handleToggle(value)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checkfaceElement(value)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                        className={classes.checkboxStyle}
                        // className="instance-checkbox instance-checkbox-all head"

                        // onChange={handleChange}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={<Typography  fontSize={12} >{value.valueLabel +" ("+value.valueCount +")" } </Typography>}
                    />
                  </ListItem>
                );
              } )}
            </List>)}
    </>
  );
}

import React, { useState, useEffect, useMemo, createRef, useRef } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import { List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Stack from '@mui/material/Stack';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';


import { useTheme } from '@mui/material/styles';

import FacetBox from './FacetBox';
import Chip from '@material-ui/core/Chip';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import ajaxGet from '../../services/ajaxGet.js';
import ajaxPost from '../../services/ajaxPost.js';


export default function FilterProduct({
  setCountFoundTotal,
  setMultiClassificationSubscribed,
  setArticleDataEdit,
  setPaginationstate,
  setClassificationGroups,
  setProductsViewList,
  facets,
  setFacets,
  contentLanguage
}) {
  const useStyles_ = makeStyles((theme) => ({
    root: {
      width: '100%',
      //   maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      overflow: 'auto',
      fontSize: '2rem',
      maxHeight: 300
    },
    listSection: {
      backgroundColor: 'inherit'
    },
    ul: {
      backgroundColor: 'inherit',
      padding: 0
    },
    column: {
      flexBasis: '33.33%'
    }, 
    heading: {
      fontSize: '1.5rem !important',
      fontWeight: '500 !important',
      color: 'white !important'
    },
    resetButton: {
      fontSize: '1.5rem !important',
      fontWeight: '500 !important',
      color: 'white !important',
      "&&:hover": {
          backgroundColor: " rgb(0 0 0 / 15%)"
       }
    },
    facet: {
      fontSize: '1.5rem !important',
      fontWeight: 'bold !important'
    }
    ,
     facet_: {
      fontSize: '0.9rem !important',
      // fontWeight: 'bold !important'
    },

    listItemButtonStyle: {
      ' &.Mui-selected': {
        backgroundColor: ' rgb(242 149 40 / 45%) !important'
      }
    }  ,
    
    resetIcon: {
      "&.MuiChip-icon": {
        color: 'white !important'
       },
       "&.MuiSvgIcon-root ": {
        color: 'white !important'
       }
    }
  }));

  const classes = useStyles_();

  const theme = useTheme();
  const [facetElement, setFacetElement] = React.useState({});
  const [allSearchIds, setAllSearchIds] = React.useState([]);
  const [checked, setChecked] = React.useState([]);
  const [filter, setFilter] = React.useState([]);


  const handleListItemClick = (fixedFacet) => {
    setFacetElement(fixedFacet);
  };


    const openFilterClick = () => {
      ajaxGet('dataManagement/facets_', {
        // isNewFilter: isNewFilter,
        // currentPage: currentPage,
        // searchWithFacets: searchWithFacets,
        // searchTerm: searchTerm,
        contentLanguage: contentLanguage
        // lastPage: lastPage,
        // productStructureNode: productStructureNode,
        // productStructureNodePath: productStructureNodePath,
        //  elementsPerPage: elementsPerPage
      }).then((ajaxData) => {
        const response = ajaxData.response;
        if (!ajaxData.error && response) {
          setFacets(response.facets);
        }
      });
    };

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
      console.log(isExpanded);
      openFilterClick();
      setExpanded(isExpanded ? panel : false);
    };

    const updateSearch = (filter) => {
      const payload = {
        searchTerm: '*',
        curPage: null,
        elementsPerPage: null,
        filters: filter,
        isNewFilter: true
      };

      ajaxPost('dataManagement_', {}, payload).then((ajaxData) => {
        let response = ajaxData.response;
        if (response)
          TOGO.Util.notifyResponse(response.message, !response.success);

        if (!ajaxData.error && response) {
          let productList = response.overviewModel.items;
          setProductsViewList([]);
          setCountFoundTotal(response.countFoundTotal);
          setArticleDataEdit(response.articleDataEdit);
          setPaginationstate(response.pagination);
          setClassificationGroups(response.groupModels);
          setFacets(response.facets);
          setMultiClassificationSubscribed(
            response.multiClassificationSubscribed
          );
          setProductsViewList(productList);

        }
      });
    };

    const resetFilterHandleClick = () => {
      setChecked([]);
      setAllSearchIds([]);
      setFilter([]);
      updateSearch([]);
      console.info( allSearchIds.length + ' : You clicked the Chip.');
    };

    const changeAllSearchIds = (item, bool) => {
      if (bool){
        allSearchIds.push(item)
        setAllSearchIds(allSearchIds);
        console.info('changeAllSearchIds' + allSearchIds);
      }else{
        setAllSearchIds(allSearchIds.filter((org) => org !== item));
        console.info('changeAllSearchIds'+ allSearchIds);
      }
      
    };
    useEffect(() => {}, [facetElement,checked, allSearchIds]);
  return (
    <div>
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
        sx={{
          backgroundColor: "#f29528"
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.resetIcon}/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>Filter</Typography>
          </div>
          {allSearchIds.length != 0 && (
            <div className={classes.column}>
           <Chip
              label="Filter zurücksetzen"
              className={classes.resetButton}
              variant="outlined"
              onClick={resetFilterHandleClick}
              clickable={true}
              icon={<AutorenewIcon className={classes.resetIcon} />}
            />
            </div>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 0.5, sm: 0.5, md: 0.5 }}

            sx={{
              width: '100%',
              height: '30rem',
              fontSize: '1.5rem',
              backgroundColor: '#fff'
            }}
          >
            <Box
              sx={{
                width: '37.5%',
                height: '30rem',
                backgroundColor: '#fff',
                fontSize: '1.5rem'
              }}
            >
              {' '}
              <List
                className={classes.root}
                component="nav"
                aria-label="secondary mailbox folder"
              >
                <ListItemButton
                  dense={true}
                  divider={true}
                  className={classes.listItemButtonStyle}
                  //   selected={selectedIndex === 0}
                  //   onClick={(event) => handleListItemClick(event, 2)}
                >
                  {/* <ListItemText primary="Trash" /> */}
                </ListItemButton>
                {facets?.fixedFacets &&
                  facets?.fixedFacets.map((fixedFacet) => (
                    <ListItemButton
                      key={fixedFacet.facetIdentifier}
                      className={classes.listItemButtonStyle}
                      selected={
                        fixedFacet.facetIdentifier ===
                        facetElement.facetIdentifier
                      }
                      onClick={(event) => {
                        handleListItemClick(fixedFacet);
                      }}
                    >
                      {/* <ListItemText
                        className={classes.heading}
                        primary={fixedFacet.label}
                      /> */}

                      <ListItemText
                        primary={
                          <Typography
                            // style={{
                            //   color: 'red',
                            //   fontSize: '1.2rem !important',
                            //   fontWeight: 'bold !important'
                            // }}
                            fontSize={12}
                            className={
                              allSearchIds.indexOf(
                                fixedFacet.facetIdentifier
                              ) != -1
                                ? classes.facet
                                : ''
                            }
                          >
                            {fixedFacet.label}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  ))}

                {facets?.combinedFacets &&
                  facets?.combinedFacets.map((combinedFacet) => (
                    <ListItemButton
                      key={combinedFacet.facetBoxId}
                      className={classes.listItemButtonStyle}
                      selected={
                        combinedFacet.facetBoxId === facetElement.facetBoxId
                      }
                      onClick={(event) => handleListItemClick(combinedFacet)}
                    >
                      <ListItemText
                        
                        primary={
                          <Typography
                          fontSize={12}
                            className={
                              allSearchIds.indexOf(
                                combinedFacet?.searchIds[0]) != -1 ? classes.facet : ''
                            }
                          >
                            {combinedFacet.label}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  ))}
              </List>
            </Box>
            <Box
              sx={{
                width: '100%',
                height: '30rem',
                backgroundColor: '#fff'
              }}
            >
              <FacetBox
                filter={filter}
                setFilter={setFilter}
                facetElement={facetElement}
                allSearchIds={allSearchIds}
                changeAllSearchIds={changeAllSearchIds}
                updateSearch={updateSearch}
                checked={checked}
                setChecked={setChecked}
              ></FacetBox>
            </Box>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

import renderReactComponentInTarget from '../renderReactComponentInTarget';

import TabEditor from 'src/jsx/articledata/tabeditor/TabEditor.jsx';

import HomeDashboard from 'src/jsx/dashboards/HomeDashboard';
import InstanceEditor from 'src/jsx/articledata/datamanagement/InstanceEditor.jsx';
import CustomerAdresses from 'src/jsx/user/CustomerAdresses.jsx';
import Users from 'src/jsx/user/Users.jsx';
import OrganizationManagement from 'src/jsx/user/OrganizationManagement.jsx';
import SFTPConnectionConfiguration from 'src/jsx/sftpUser/SFTPConnectionConfiguration';
import ClassificationGroup from 'src/jsx/classificationGroup/ClassificationGroup';
import OutputChannel from 'src/jsx/outputChannel/OutputChannel';
import DataModelOverview from 'src/jsx/bmecatconfig/DataModelOverview.jsx';
import BMEcatExportSpecs from 'src/jsx/bmecatconfig/BMEcatExportSpecs.jsx';
import ExportOverview from 'src/jsx/bmecatexport/ExportOverview.jsx';
import PtdDefaultDataOverview from 'src/jsx/producttype/PtdDefaultDataOverview';
import ShopConfigOverview from 'src/jsx/shop/ShopConfigOverview.jsx';
import Classificator from 'src/jsx/classificator/Classificator.jsx';
import ProductItem from 'src/jsx/ProductList/ProductItem';
import SearchProductInput from 'src/jsx/ProductList/SearchProductInput';
import AdjustPermissionsDashboard from 'src/jsx/dashboards/AdjustPermissionsDashboard';
import DataSheetTabs from 'src/jsx/dashboards/DataSheetTabs';
// import TableDefinitionDashboard from 'src/jsx/dashboards/TableDefinitionDashboard';


import 'src/i18n';


renderReactComponentInTarget(HomeDashboard, '#react-HomeDashboard');
renderReactComponentInTarget(InstanceEditor,'#react-InstanceEditor','togo.datamanagement.loadproduct');
renderReactComponentInTarget(TabEditor, '#react-TabEditor');
renderReactComponentInTarget(CustomerAdresses, '#react-CustomerAdresses');
renderReactComponentInTarget(Users, '#react-Users');
renderReactComponentInTarget(OrganizationManagement, '#react-OrganizationManagement');
renderReactComponentInTarget(DataModelOverview, '#react-DataModelOverview');
renderReactComponentInTarget(BMEcatExportSpecs, '#react-BMEcatExportSpecs');
renderReactComponentInTarget(ExportOverview, '#react-BMEcatExportOverview');
renderReactComponentInTarget(PtdDefaultDataOverview,'#react-PtdDefaultDataOverview');
renderReactComponentInTarget(ShopConfigOverview, '#react-ShopConfigOverview');
renderReactComponentInTarget(SFTPConnectionConfiguration,'#react-SFTPConfigOverview');
renderReactComponentInTarget(ClassificationGroup,'#react-classificationGroupsOverview');
renderReactComponentInTarget(OutputChannel,'#react-outputchannelsOverview');
renderReactComponentInTarget(Classificator, '#react-Classificator');
renderReactComponentInTarget(ProductItem,'#react-productItemOverview');
renderReactComponentInTarget(SearchProductInput, '#react-searchProductInput');
renderReactComponentInTarget(AdjustPermissionsDashboard, '#react-AdjustPermissionsDashboard');
renderReactComponentInTarget(DataSheetTabs, '#react-TableDefinition');


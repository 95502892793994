import { createTheme, makeStyles } from '@material-ui/core/styles';
import { deDE, enUS, frFR, itIT } from '@material-ui/core/locale';

let materialUiLang;
switch (document.documentElement.lang) {
  case 'de':
    materialUiLang = deDE;
    break;
  case 'en':
    materialUiLang = enUS;
    break;
  case 'fr':
    materialUiLang = frFR;
    break;
  case 'it':
    materialUiLang = itIT;
    break;

  default:
    materialUiLang = enUS;
    break;
}

const theme = createTheme(
  {
    palette: {
      primary: {
        light: '#6b93ff',
        main: '#0066ff',
        dark: '#003dcb',
        contrastText: '#fff'
      },
      secondary: {
        light: '#ffca47',
        main: '#f29528',
        dark: '#c66a00',
        contrastText: '#fff'
      }
    },
    typography: {
      htmlFontSize: 10
    },
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: '1em'
        }
      },
      MuiDialogTitle: {
        root: {
          backgroundColor: '#4d4c47',
          '& .MuiTypography-root': {
            color: 'white'
          }
        }
      },
      MuiDialogContent: {
        root: {
          marginTop: '2rem'
        }
      }
    }
  },
  materialUiLang
);
export default theme;

export const usePimStyles = makeStyles(() => ({
  addAction: {
    color: 'white',
    backgroundColor: '#5cb85c !important',
    borderColor: '#5cb85c',

    '&:hover': {
      backgroundColor: '#458c45 !important'
    }
  },
  deleteAction: {
    color: 'white',
    backgroundColor: '#d9534f',
    borderColor: '#d43f3a',
    '&:hover': {
      backgroundColor: '#aa3e3b'
    }
  },
  deleteActionMenuButton: {
    color: '#d43f3a'
  },
  editAction: {
    color: 'white',
    backgroundColor: '#f29528',
    borderColor: '#ea9026',
    '&:hover': {
      backgroundColor: '#c66a00'
    }
  },
  editActionMenuButton: {
    color: '#000000de'
  },
  dialog: {
    '& .MuiDialog-container > .MuiDialog-paper': {
      borderRadius: '0.6rem',
      boxShadow: '0 0 1em black',
      '& > div > .MuiPaper-root': {
        padding: '0 2rem !important',
        boxShadow: 'none',
        overflow: 'auto',
        maxHeight: '70vh'
      }
    }
  },
  dialogTitle: {
    backgroundColor: '#333 !important',
    position: 'sticky',
    top: '0',
    zIndex: '9',
    '& > *': {
      color: 'white !important',
    }
  },
  dialogBottom: {
    position: 'sticky',
    bottom: '0',
    zIndex: '9',
    marginTop: '1rem'
  },
  gridItem: {
    margin: '8px'
  },
  dialogTableDefinition: {
    '& .MuiDialog-container > .MuiDialog-paper': {
      borderRadius: '0.6rem',
      boxShadow: '0 0 1em black',
      '& > div > .MuiPaper-root': {
        padding: '0 2rem !important',
        boxShadow: 'none',
        overflow: 'auto',
        maxHeight: '70vh',
        maxWidth:'800px !important'
      }
    }
  }
}));

import React from 'react';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Icon,
  makeStyles,
  Tooltip
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '7rem'
  },
  toolItem: {
    cursor: 'grab'
  },
  toolHeader: {
    height: '1rem',
    padding: 0,
    backgroundColor: '#ffc56e'
  },
  toolHeaderIcons: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.4rem',
    position: 'relative',
    right: '1.2rem',
    marginRight: '0.1rem'
  },
  toolContent: {
    padding: 0,
    paddingBottom: '10px !important',
    textAlign: 'center'
  },
  toolIcon: {
    position: 'relative',
    top: '0.7rem'
  }
}));

const ICON_MAPPING = {
  Text: 'fa fa-quote-left fa-2x',
  SubscriptionQuota: 'fa fa-tasks fa-2x',
  SubscriptionFeatureList: 'fa fa-list fa-2x',
  LinkList: 'fa fa-star fa-2x',
  ArticleDataZones: 'fa fa-eye ',
  OrganizationExports:'fa fa-list-alt fa-2x'
};

export default function Tool({ toolConfig }) {
  const classes = useStyles();

  const faIcon = ICON_MAPPING[toolConfig.toolType];

  return (
    <Box className={classes.root}>
      <Tooltip title={toolConfig.toolType} placement="top" arrow>
        <Card
          className={classes.toolItem}
          draggable={true}
          onDragStart={(event) => {
            event.dataTransfer.setData('toolTypeForPanel', toolConfig.toolType);
          }}
        >
          <CardHeader
            className={classes.toolHeader}
            disableTypography={true}
            action={
              <>
                <Icon className={`fa fa-circle ${classes.toolHeaderIcons}`} />
                <Icon className={`fa fa-circle ${classes.toolHeaderIcons}`} />
              </>
            }
          />
          <CardContent className={classes.toolContent}>
            <Icon className={`${faIcon} ${classes.toolIcon}`} />
          </CardContent>
        </Card>
      </Tooltip>
    </Box>
  );
}
